import React, { useState } from 'react';
import '../App.css';
import '../css/button.css';
import { projectsData } from '../components/projectdata.tsx';
import '@fortawesome/fontawesome-free/css/all.css';

function Projects() {
    const [selectedLanguage, setSelectedLanguage] = useState(null);
    const [expandedCard, setExpandedCard] = useState(null);
    const [showOtherCards, setShowOtherCards] = useState(true);

    const handleLanguageClick = (language) => {
        setSelectedLanguage(language);
        setExpandedCard(null);
        setShowOtherCards(true); // Ensure other cards are shown when language filter is applied
    }

    const handleShowAll = () => {
        setSelectedLanguage(null);
        setExpandedCard(null);
        setShowOtherCards(true); // Ensure other cards are shown when showing all projects
    }

    const ProjectCard = ({ project }) => {
        const handleClick = () => {
            setExpandedCard(project);
            setShowOtherCards(false); // Hide other cards when one card is expanded
        };

        return (
            <div className={`project-card ${expandedCard === project ? 'expanded' : ''}`} onClick={handleClick}>
                <div className='image'>
                    <img src={project.image} alt={project.title} />
                </div>
                <div className="overlay">
                    <h2>{project.title}</h2>
                    <div className="language-box">{project.language}</div>
                </div>
            </div>
        );
    };

    const ExpandedCard = ({ project }) => {
        const handleBack = () => {
            setExpandedCard(null);
            setShowOtherCards(true); // Show other cards when expanded card is closed
        };

        return (
            <div className='expanded-card'>
                <div className="project-details">
                    <div className="expanded-card-title">
                        <h1>{project.title}</h1>
                        <div className="expanded-language-box">
                            <p>{project.language}</p>
                        </div>
                    </div>
                </div>

                <p>{project.description}</p>

                <div className="video-wrapper">
                    <iframe
                        width="500px"
                        height="400px"
                        src={project.video}
                        title={project.title}
                        allowFullScreen
                    ></iframe>
                </div>
                <div className='git-frame'>
                    <a href={project.link} target="_blank" rel="noopener noreferrer" className="github-link">
                        <i className="fab fa-github" style={{ fontSize: '64px' }}></i>
                        <div className="github-text">View Code</div>
                    </a>
                </div>

                

                <div className='back-button' onClick={handleBack}>
                    Return
                </div>
            </div>
        );
    };

    const filteredProjects = selectedLanguage ? projectsData.filter(project => project.language === selectedLanguage) : projectsData;

    return (
        <div>
            <div className='pad'/>
            <div className='cornered-box'>
                <h1 style={{ color: 'white' }}>Projects</h1>
            </div>
            <div className="align-center">
                <div className="button-container">
                    <div className={`btn btn-gray btn-animate ${selectedLanguage === 'C++' ? 'btn-selected' : ''}`} onClick={() => handleLanguageClick('C++')}>C++</div>
                    <div className={`btn btn-gray btn-animate ${selectedLanguage === 'C' ? 'btn-selected' : ''}`} onClick={() => handleLanguageClick('C')}>C</div>
                    <div className={`btn btn-gray btn-animate ${selectedLanguage === 'Python' ? 'btn-selected' : ''}`} onClick={() => handleLanguageClick('Python')}>Python</div>
                    <div className={`btn btn-gray btn-animate ${selectedLanguage === null ? 'btn-selected' : ''}`} onClick={handleShowAll}>Show All</div>
                </div>
                <p className='text-below'>Filter by language</p>
            </div>
            <div className='project-container'>
                {showOtherCards && filteredProjects.map((project, index) => (
                    <ProjectCard key={index} project={project} />
                ))}
            </div>
            {expandedCard && <ExpandedCard project={expandedCard} />}
        </div>
    );
}

export default Projects;
