import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';

function About() {
    const containerRef = useRef(null);
    const canvasRef = useRef(null);
    const cubeRef = useRef(); // Reference for the cube
    const rendererRef = useRef(); // Reference for the renderer
    const cameraRef = useRef(); // Reference for the camera
    const animationIdRef = useRef(null); // Reference for animation frame ID

    useEffect(() => {
        const scene = new THREE.Scene();
        const camera = new THREE.PerspectiveCamera(90, window.innerWidth / window.innerHeight, 0.1, 12000);
        const renderer = new THREE.WebGLRenderer({ canvas: canvasRef.current });
        renderer.setSize(window.innerWidth, window.innerHeight);
        rendererRef.current = renderer; // Save renderer reference
        cameraRef.current = camera; // Save camera reference

        // Set background color
        renderer.setClearColor(0x0e0f16);

        const loader = new GLTFLoader();

        const handleError = (error) => {
            console.error(error);
        };
        
        const loadCube = () => {
            loader.load('/models/AboutMeCube.gltf', handlespaceSceneRefLoad, undefined, handleError);
        };
        
        // Delay the loading of the space scene
        setTimeout(loadCube, 0);
        
        const handlespaceSceneRefLoad = (gltf) => {
            cubeRef.current = gltf.scene;
            cubeRef.current.scale.set(0.05, 0.05, 0.05);
            cubeRef.current.position.y = -5;
            scene.add(cubeRef.current);
            console.log("Cube rendered.");
            console.log(cubeRef.current.position);
        };

        const pointLight1 = new THREE.PointLight(0xffffff, 3000000, 1000);
        pointLight1.position.set(0, 0, 400);
        scene.add(pointLight1);

        // Set initial camera position
        camera.position.z = 10;

        // Set up resize handling
        const handleResize = () => {
            const width = containerRef.current.clientWidth;
            const height = containerRef.current.clientHeight;

            // Adjust camera aspect ratio
            camera.aspect = width / height;
            camera.updateProjectionMatrix();

            // Resize renderer
            renderer.setSize(width, height);
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Initial resize

        // Animate the scene
        const animate = () => {
            renderer.render(scene, camera);
            animationIdRef.current = requestAnimationFrame(animate);
        };
        animate();

        // Cleanup
        return () => {
            window.removeEventListener('resize', handleResize);
            scene.remove(cubeRef.current);
            renderer.dispose();
            cancelAnimationFrame(animationIdRef.current);
        };
    }, []);

    const rotateCubeClockwise = () => {
        if (cubeRef.current) {
            animateRotation(-Math.PI / 2); // Rotate clockwise by 90 degrees (π/2 radians)
        }
    };

    const rotateCubeCounterClockwise = () => {
        if (cubeRef.current) {
            animateRotation(Math.PI / 2); // Rotate counterclockwise by 90 degrees (π/2 radians)
        }
    };

    const animateRotation = (angle) => {
        const initialRotation = cubeRef.current.rotation.y;
        const targetRotation = initialRotation + angle;
        const duration = 500; // Animation duration in milliseconds
        const startTime = Date.now();

        const updateRotation = () => {
            const elapsed = Date.now() - startTime;
            const progress = Math.min(elapsed / duration, 1); // Ensure progress is between 0 and 1
            cubeRef.current.rotation.y = initialRotation + progress * angle;

            if (progress < 1) {
                requestAnimationFrame(updateRotation); // Continue animation until duration is reached
            }
        };

        updateRotation(); // Start the animation
    };

    return (
        <div ref={containerRef} style={{ width: '100%', height: '700px', position: 'relative' }}>
            <canvas ref={canvasRef} style={{ width: '100%', height: '100%' }} />
            {/* Left arrow button */}
            <button style={{ position: 'absolute', left: '90%', top: '30%', width: '50px', transform: 'translate(-50%, -50%)', zIndex: 999, background: 'none', border: 'none' }} onClick={rotateCubeClockwise}>
                <FontAwesomeIcon icon={faArrowLeft} style={{ fontSize: '36px', color: '#fff' }} />
            </button>
            {/* Right arrow button */}
            <button style={{ position: 'absolute', right: '10%', top: '70%', width: '50px', transform: 'translate(50%, -50%)', zIndex: 999, background: 'none', border: 'none' }} onClick={rotateCubeCounterClockwise}>
                <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '36px', color: '#fff' }} />
            </button>
        </div>
    );
    
}

export default About;
