import React, { useEffect, useRef, useState } from 'react';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import TWEEN from '@tweenjs/tween.js';

function Space() {
    const canvasRef = useRef();
    const spaceSceneRef = useRef();
    const rendererRef = useRef(); // Reference for the renderer
    const cameraRef = useRef(); // Reference for the camera
    const controlsRef = useRef(); // Reference for OrbitControls
    const [showAPopup, setShowAPopup] = useState(false);
    const [showInstructions, setShowInstructions] = useState(false);
    const [showPortalButtons, setShowPortalButtons] = useState(false);
    const [showLoading, setShowLoading] = useState(false);

    const animateCameraTo = (destination, duration) => {
        const camera = cameraRef.current;
        const initialPosition = camera.position.clone();
        const targetPosition = new THREE.Vector3(destination.x, destination.y, destination.z);
        const easingFunction = TWEEN.Easing.Quadratic.InOut; // Adjust easing function as needed
    
        new TWEEN.Tween({ t: 0 })
            .to({ t: 1 }, duration)
            .easing(easingFunction)
            .onUpdate(({ t }) => {
                const newPosition = initialPosition.clone().lerp(targetPosition, t);
                camera.position.copy(newPosition);
            })
            .start();
    };
    
    const flyToPortal = (destination) => {
        animateCameraTo(destination, 1500);

        switch (destination) {
            case destinations[0]:
                animateCameraTo({ x: -32.71672933620681, y: 392.5336576370402, z: -87.3065523836753 }, 3500);
                setShowLoading(true);
                break;
            case destinations[1]:
                animateCameraTo({ x: -32.71672933620681, y: 422.52939407885845, z: 723.6856075692496 }, 3500);
                setShowLoading(true);
                break;
            case destinations[2]:
                animateCameraTo({ x: -32.96867385691334, y: 398.84971925927226, z: 1521.284921212421 }, 3500);
                setShowLoading(true);
                break;
            default:
                break;
        }
    
        // Navigate to corresponding page after camera animation
        setTimeout(() => {
            switch (destination) {
                case destinations[0]:
                    window.location.href = '/contact-me';
                    break;
                case destinations[1]:
                    window.location.href = '/projects';
                    break;
                case destinations[2]:
                    window.location.href = '/about-me';
                    break;
                default:
                    break;
            }
        }, 3500); // Adjust delay to match animation duration
    };
    

    useEffect(() => {
        const scene = new THREE.Scene();
        const camera = new THREE.PerspectiveCamera(90, window.innerWidth / window.innerHeight, 0.1, 12000);
        const renderer = new THREE.WebGLRenderer({ canvas: canvasRef.current });
        renderer.setSize(window.innerWidth, window.innerHeight);
        rendererRef.current = renderer; // Save renderer reference
        cameraRef.current = camera; // Save camera reference

        const loader = new GLTFLoader();

        const handleError = (error) => {
            console.error(error);
        };
        
        const loadSpaceScene = () => {
            loader.load('/models/Launch/ModelLoad.gltf', handlespaceSceneRefLoad, undefined, handleError);
        };
        
        // Delay the loading of the space scene
        setTimeout(loadSpaceScene, 1700);
        
        const handlespaceSceneRefLoad = (gltf) => {
            spaceSceneRef.current = gltf.scene;
            spaceSceneRef.current.scale.set(0.5, 0.5, 0.5);
            scene.add(spaceSceneRef.current);
            console.log("Space Scene rendered.");
            console.log(spaceSceneRef.current.position);
        
            // Show "Click on the Astronaut" button after 22 seconds
            setTimeout(() => {
                setShowAPopup(true);
            }, 10200);
        };
        
        // Create a star particle system
        const starsGeometry = new THREE.BufferGeometry();
        const starCount = 5000;
        const positions = [];

        for (let i = 0; i < starCount; i++) {
            const x = (Math.random() - 0.5) * 10000;
            const y = (Math.random() - 0.5) * 10000;
            const z = (Math.random() - 0.5) * 10000;
            positions.push(x, y, z);
        }

        starsGeometry.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3));

        const starsMaterial = new THREE.PointsMaterial({ color: 0xffffff, size: 1 });

        const stars = new THREE.Points(starsGeometry, starsMaterial);
        scene.add(stars);

        const pointLight1 = new THREE.PointLight(0xffffff, 3000000, 100000);
        pointLight1.position.set(-550, 975, -156);
        scene.add(pointLight1);

        const pointLight2 = new THREE.PointLight(0xffffff, 3000000, 100000);
        pointLight2.position.set(-550, 975, 670);
        scene.add(pointLight2);

        const pointLight3 = new THREE.PointLight(0xffffff, 3000000, 100000);
        pointLight3.position.set(-550, 975, 1500);
        scene.add(pointLight3);

        const pointLight4 = new THREE.PointLight(0xffffff, 3000000, 100000);
        pointLight4.position.set(-1100, 660, 1700);
        scene.add(pointLight4);

        const pointLight5 = new THREE.PointLight(0xffffff, 3000000, 100000);
        pointLight5.position.set(-550, 1500, 1500);
        scene.add(pointLight5);

        camera.position.x = -200;

        const destinations = [
            {x: -370.51127445786506, y: 3932.705446086326, z: 762.6953382570963},
            {x: -335.03941888632244, y: 2827.3758747953616, z: 791.5991030231668},
            {x: -2912.46632662871, y: 3377.3306959980487, z: 780.3354755267028},
            {x: -1546.1707788672752, y: 623.0047500637651, z: 704.7795500469424}
        ];

        const flyToDestinations = () => {
            let delay = 0;
            for (let i = 0; i < destinations.length; i++) {
                setTimeout(() => {
                    animateCameraTo(destinations[i], 2000); // Adjust duration as needed
                }, delay);
                delay += 3000; // Adjust delay between destinations as needed (duration + gap)
            }
        };

        // Start flying to destinations after a delay
        setTimeout(flyToDestinations, 1000); // Adjust delay before starting as needed

        // Initialize OrbitControls
        const controls = new OrbitControls(camera, renderer.domElement);
        controlsRef.current = controls;

        // Add event listener to controls for changes
        controls.addEventListener('change', () => {
            console.log('Camera position:', camera.position);
        });

        const animate = () => {
            TWEEN.update(); // Update TWEEN animations
            renderer.render(scene, camera);
            requestAnimationFrame(animate);
        };

        animate();

        const handleWindowResize = () => {
            const width = window.innerWidth;
            const height = window.innerHeight;
            renderer.setSize(width, height);
            camera.aspect = width / height;
            camera.updateProjectionMatrix();
        };

        window.addEventListener('resize', handleWindowResize);

        return () => {
            scene.remove();
            renderer.dispose();
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    const handleAstronautClick = () => {
        setShowAPopup(false);
        setShowInstructions(true);
        setShowPortalButtons(true);
    };

    const destinations= [
        {x: -124.31308609754808, y: 392.5336576370402, z: -87.3065523836753},
        {x: -127.65912901308545, y: 438.2498293360724, z: 723.5268879953478},
        {x: -126.96867385691334, y: 398.84971925927226, z: 1521.284921212421}
    ]

    const handlePortal1Click = () => {
        flyToPortal(destinations[0]); // Navigate to about-me page
    };
    
    const handlePortal2Click = () => {
        flyToPortal(destinations[1]); // Navigate to projects page
    };
    
    const handlePortal3Click = () => {
        flyToPortal(destinations[2]); // Navigate to contact-me page
    };

    return (
        <div>
            <canvas ref={canvasRef} />
            {showAPopup && (
                <div>
                    <div className='space-instructions-frame'>
                        <div className='instructions'>
                            Talk to the Astronaut
                        </div>
                    </div>
                    <button className='astronaut' onClick={handleAstronautClick} />
                </div>
            )}
            {showInstructions && (
                <div className='space-instructions-frame' style={{ height: '200px' }}>
                    <div className='instructions'>Welcome to my site! Click on a portal to navigate the other dimensions!</div>
                </div>
            )}
            {showPortalButtons && (
                <div>
                    <button className='portal' onClick={handlePortal1Click} />
                    <button className='portal' style={{ left: '45%' }} onClick={handlePortal2Click} />
                    <button className='portal' style={{ left: '58%' }} onClick={handlePortal3Click} />
                </div>
            )}
            {showLoading && (
                <div className='loading-screen'>
                    <div className='loading-spinner'/>
                </div>
            )}
        </div>
    );
}

export default Space;
