import lockIcon from './images/lock-icon.jpg';
import smsLogo from './images/SMSLogo.png'

export const projectsData = [
    {
        title: 'Student Management System',
        description: `
        Utilized C with dynamic memory allocation and linked lists to create
        a student records system which has many different functionalities including:
        creating and removing students, storing student info, backup system into local computer file, and more.
        `,
        language: 'C',
        video: 'https://www.youtube.com/embed/_GztP-t5egg',
        link: 'https://github.com/MichaelDev11/Student-Management-System',
        image: smsLogo
    },
    {
        title: 'Password Manager Application',
        description: `Created a password manager application that allows a user to create and store passwords on their computer.
        This application used encryption techniques and a user authentication system for retrieving and storing passwords.
        `,
        language: 'C++',
        video: 'https://www.youtube.com/embed/wDfYxZohoxI',
        link: 'https://github.com/MichaelDev11/Password-Manager',
        image: lockIcon
    }
];
