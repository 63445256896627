// about-me.tsx
import React from 'react';
import '../css/about.css';
import About from '../scenes/aboutme.js';

function AboutMe() {
    return (
        <div>
            <div className='pad'/>
            <div className='cornered-box'>
                <h1 style={{ color: 'white' }}>About Me</h1>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div className='scene-info-frame'>
                    <div className='scene'>
                        <About />
                    </div>
                    <div className='info'>
                        <div className='info-text'>
                            <p>
                                Hi! I am Mike, I am currently pursuing a degree in Computer Engineering with a minor in Physics 
                                at the University of South Florida. Originally interested with electronics, my interests expanded 
                                when I discovered programming in my sophomore year in high school, leading me to choose Computer 
                                Engineering. I have a keen interests towards Robotics, Hardware Engineering, Software Engineering, 
                                and recently Embedded Systems. Beyond academia, I prioritize a balance between academic pursuits and 
                                personal interests by going to the gym, quality time with friends, and even honing my skills with 
                                tinkering with electronics such as Arduino microcontrollers. I'm open to discussing potential collaboration 
                                or job opportunities! Feel free to connect with me through my Contact Me page!
                            </p>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    );
}


export default AboutMe;
