import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'; // Import OrbitControls

function Landing() {
    const canvasRef = useRef();
    const shuttleRef = useRef();
    const planetRef = useRef();
    const backgroundRef = useRef();
    const rendererRef = useRef(); // Reference for the renderer
    const cameraRef = useRef(); // Reference for the camera
    const controlsRef = useRef(); // Reference for OrbitControls

    useEffect(() => {
        const scene = new THREE.Scene();
        const camera = new THREE.PerspectiveCamera(90, window.innerWidth / window.innerHeight, 0.1, 2000);
        const renderer = new THREE.WebGLRenderer({ canvas: canvasRef.current });
        renderer.setSize(window.innerWidth, window.innerHeight);
        rendererRef.current = renderer; // Save renderer reference
        cameraRef.current = camera; // Save camera reference

        // Initialize OrbitControls
        const controls = new OrbitControls(camera, renderer.domElement);
        controlsRef.current = controls; // Save controls reference

        const loader = new GLTFLoader();

        const handleShuttleLoad = (gltf) => {
            shuttleRef.current = gltf.scene;
            shuttleRef.current.scale.set(0.15, 0.15, 0.15);
            shuttleRef.current.position.x = -8;
            shuttleRef.current.position.z = 125;
            shuttleRef.current.rotation.y -= 0.25;
            shuttleRef.current.rotation.x += 0.4;
            shuttleRef.current.rotation.z -= 1.0;
            scene.add(shuttleRef.current);
            console.log("Shuttle rendered.");
        };

        const handleEarthLoad = (gltf) => {
            planetRef.current = gltf.scene;
            planetRef.current.scale.set(0.3, 0.3, 0.3);
            planetRef.current.position.set(-100, -50, 0);
            planetRef.current.rotation.x -= 250;
            scene.add(planetRef.current);
            console.log("Planet rendered.");
        };
        
        const handleError = (error) => {
            console.error(error);
        };

        loader.load('/models/Launch/SpaceShuttle.glb', handleShuttleLoad, undefined, handleError);
        loader.load('/models/Launch/Earth2.glb', handleEarthLoad, undefined, handleError);

        camera.position.set(41.503909579431031, -4.5070711424369, 164.7771295605373);

        // Add directional light
        const light = new THREE.DirectionalLight(0xffffff, 3);
        light.position.set(10, 2, 40);
        scene.add(light);

        // Create a star particle system
        const starsGeometry = new THREE.BufferGeometry();
        const starCount = 10000;
        const positions = [];

        for (let i = 0; i < starCount; i++) {
            const x = (Math.random() - 0.5) * 10000;
            const y = (Math.random() - 0.5) * 10000;
            const z = (Math.random() - 0.5) * 10000;
            positions.push(x, y, z);
        }

        starsGeometry.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3));

        const starsMaterial = new THREE.PointsMaterial({ color: 0xffffff, size: 1 });

        const stars = new THREE.Points(starsGeometry, starsMaterial);
        scene.add(stars);

        const animate = () => {
            if (planetRef.current) {
                planetRef.current.rotation.z -= 0.00001; // Rotate planet
                planetRef.current.rotation.x += 0.00001;
            }

            // Update OrbitControls
            if (controlsRef.current) {
                controlsRef.current.update();
                console.log(camera.position);
            }

            renderer.render(scene, camera);
            requestAnimationFrame(animate);
        };

        animate();

        const handleWindowResize = () => {
            const width = window.innerWidth;
            const height = window.innerHeight;

            renderer.setSize(width, height);
            camera.aspect = width / height;
            camera.updateProjectionMatrix();
        };

        window.addEventListener('resize', handleWindowResize);

        return () => {
            scene.remove();
            renderer.dispose();
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    return <canvas ref={canvasRef} />;
}

export default Landing;
