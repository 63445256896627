import React, { useState } from 'react';
import Landing from '../scenes/landing.js';
import Space from '../scenes/space.js'; // Import the Space component
import '../css/welcome.css';

function Home() {
    const [showLanding, setShowLanding] = useState(true);
    const [showLoading, setShowLoading] = useState(false);

    const handleButtonClick = () => {
        setShowLanding(false); // Set showLanding to false when the button is clicked
        setShowLoading(true);
        
        // Show loading screen for 1.5 seconds
        setTimeout(() => {
            setShowLoading(false);
        }, 1500);
    };

    return (
        <div>
            <div className='HideOverflow'>
                {showLanding ? <Landing /> : <Space />} {/* Render Landing or Space based on showLanding */}
                {showLanding && (
                    <div className='continue-menu'>
                        <div className='menu-text'>Michael Perkins Portfolio Site<br/>Welcome!</div>
                        <div className='continue-button'>
                            <button onClick={handleButtonClick}>Explore !</button> {/* Add onClick handler */}
                        </div>
                    </div>
                )}
            </div>
            {showLoading && (
                <div className='loading-screen'>
                    <div className='loading-spinner'/>
                </div>
            )}
        </div>
    );
}

export default Home;